import React from "react"
import { navigate } from "gatsby"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import {
  getCourseNavigation,
  useCourseStore,
  getCourseProgress,
} from "../../../../../store/courses"
import Note from "../../../../../components/note"
import Column from "../../../../../components/column"
import Columns from "../../../../../components/columns"
import Input from "../../../../../components/input"
import Text from "../../../../../components/text"
import Inline from "../../../../../components/inline"
import Divider from "../../../../../components/divider"

const taskProps = {
  courseId: "hoelderlins-reisen",
  chapterId: "01-friedrich-hoelderlin",
  taskId: "reisekosten",
}

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation(taskProps)
  const progress = getCourseProgress(taskProps)

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          ...taskProps,
          taskId: "reisekosten-anreise",
        })
        navigate(
          "/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/reisekosten/speier"
        )
      }}
      navigation={navigation}
      progress={progress}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Reisekosten berechnen" />
      <Stack>
        <Heading as="h2" level={2}>
          Reisekosten berechnen
        </Heading>
        <Paragraph>
          Wie du bereits weißt, hat Hölderlin auf seiner Reise alle Kosten genau
          festgehalten. Seine Mutter war sehr sparsam. Er sollte nicht zu viel
          Geld ausgeben. Hat er das geschafft? Um das herauszufinden, müssen wir
          seine Reisekosten zusammen rechnen. Damals zahlte man noch nicht mit
          Euro, sondern mit Kreuzern (cr.).
        </Paragraph>
        <Note variant="task">
          Rechne aus, wieviele Kreuzer Hölderlin auf seiner Reise ausgegeben
          hat. Nimm dir dafür ein Blatt Papier zur Hilfe. Wir starten mit dem
          Weg nach Speier.
        </Note>
        <Stack space={6}>
          <Heading as="h3" level={6}>
            Auf dem Weg nach Speier
          </Heading>
          <Stack space={3}>
            <Columns alignY="center">
              <Column>
                <Text>In Bruchsaal Zeche</Text>
              </Column>
              <Column width="content" textAlign="right">
                43 cr.
              </Column>
            </Columns>
            <Divider />

            <Columns alignY="center">
              <Column>
                <Text>Fahrlohn über den Rhein</Text>
              </Column>
              <Column width="content" textAlign="right">
                8 cr.
              </Column>
            </Columns>
            <Divider />

            <Columns alignY="center">
              <Column>
                <Text>Zu Rheinhausen Zeche</Text>
              </Column>
              <Column width="content" textAlign="right">
                7 cr.
              </Column>
            </Columns>
            <Divider />

            <Columns alignY="center">
              <Column>
                <Text>Wieder Fahrlohn über den Rhein</Text>
              </Column>
              <Column width="content" textAlign="right">
                24 cr.
              </Column>
            </Columns>
            <Divider />

            <Columns alignY="center">
              <Column>
                <Text>In der Mannheimer Comedie</Text>
              </Column>
              <Column width="content" textAlign="right">
                48 cr.
              </Column>
            </Columns>
            <Divider />

            <Columns alignY="center">
              <Column>
                <Text>Dem Mannheimer Peruqieu</Text>
              </Column>
              <Column width="content" textAlign="right">
                24 cr.
              </Column>
            </Columns>
            <Divider />

            <Columns alignY="center">
              <Column>
                <Text>Zu Frankenthal zahlt ich die Zeche</Text>
              </Column>
              <Column width="content" textAlign="right">
                118 cr.
              </Column>
            </Columns>
          </Stack>
          <Divider size={4} />
          <Inline alignY="center" alignX="right">
            <Text>Auf dem Weg nach Speier</Text>
            <Box sx={{ width: 24 }}>
              <Input id="kosten" name="kosten" />
            </Box>
            <Text>cr.</Text>
          </Inline>
        </Stack>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
